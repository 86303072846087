import localeAntd from 'antd/es/locale/ar_EG'

const messages = {
  'topBar.issuesHistory': 'Issues',
  'topBar.projectManagement': 'Projects',
  'topBar.typeToSearch': 'Search...',
  'topBar.findPages': 'Find pages...',
  'topBar.actions': 'Actions',
  'topBar.status': 'Status',
  'topBar.profileMenu.hello': 'Hello',
  'topBar.profileMenu.billingPlan': 'Billing Plan',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone',
  'topBar.profileMenu.editProfile': 'Edit Profile',
  'topBar.profileMenu.logout': 'Logout',
  'component.common.success': 'Success',
  'component.common.error': 'Error',
  'component.common.add.success': 'Added information successfully',
  'component.common.update.success': 'Updated information successfully',
  'component.common.update.error': 'Something went wrong!',
  'component.common.update.error.700': 'Something went wrong!',
  'component.common.update.error.701': 'There are cities attached to this state',
  'component.common.delete.success': 'Deleted successfully',
  'component.common.save': 'Save Information',
  'component.common.update': 'Update Information',
  'component.common.title': 'Title',
  'component.common.description': 'Description',
  'component.common.name': 'Name',
  'component.common.code': 'Code',
  'component.common.status': 'Status',
  'component.common.action': 'Action',
  'component.common.edit': 'Edit',
  'component.common.delete': 'Delete',
  'component.common.enabled': 'Enabled',
  'component.common.disabled': 'Disabled',
  'component.common.all': 'All',
  'component.common.new': 'New',
  'component.common.information': 'Information',
  'component.common.submit': 'Submit',
  'component.common.cancel': 'Cancel',
  'component.common.loading': 'Loading...',
  'component.common.locale': 'Locale',
  'component.common.cdn': 'CDN Link',
  'component.common.sort_order': 'Sort order',
  'component.common.icon': 'Icon',
  'component.common.image': 'Image',
  'component.common.ltr': 'LTR',
  'component.common.symbol_left': 'Symbol Left',
  'component.common.symbol_right': 'Symbol Right',
  'component.common.decimal_place': 'Decimal Place',
  'component.common.value': 'Value',
  'component.common.upload': 'Upload',
  'component.common.enter': 'Enter',
  'component.common.slug': 'Slug',
  'component.common.group': 'Group',
  'component.common.question': 'Question',
  'component.common.answer': 'Answer',
  'component.common.width': 'Width',
  'component.common.date_start': 'Start Date',
  'component.common.date_end': 'End Date',
  'component.common.location': 'Location',
  'component.common.slider': 'Slider',
  'component.common.sliders': 'Sliders',
  'component.common.dates': 'Dates',
  'component.common.featured': 'Featured',
  'component.common.yes': 'Yes',
  'component.common.no': 'No',
  'component.common.collection': 'Collection',
  'component.common.app': 'App',
  'component.common.settings': 'Settings',
  'component.common.coming_soon': 'Coming Soon',
  'component.common.order_image': 'Order Image',
  'component.common.big': 'Big',
  'component.common.color': 'Color',
  'component.common.tags': 'Tags',
  'component.common.instruction': 'Instruction',
  'component.common.evcode': 'EVCode',
  'component.common.amount': 'Amount',
  'component.common.placeholder': 'Search Plans',
  'component.common.Searchservice': 'Search Service Providers',
  'component.catalog': 'Catalog',
  'component.catalog.plan': 'Plan',
  'component.catalog.plans': 'Plans',
  'component.services.service': 'Service',
  'component.services.services': 'Services',
  'component.services.placeholder': 'Search Services',
  'component.services.service_provider': 'Service Provider',
  'component.services.service_providers': 'Service Providers',
  'component.sales.orders': 'Orders',
  'component.sales.placeholder': 'Search Orders',
  'component.orders.order_id': 'Order ID',
  'component.orders.pin': 'PIN',
  'component.orders.instruction': 'Instruction',
}

export default {
  locale: 'ar-EG',
  localeAntd,
  messages,
}
